"use client";

import useSWR from "swr";
import { GroupApi, GroupGetWithImageRequest } from "@/restapi/group";

export const useGroupGetWithImage = (request: GroupGetWithImageRequest) => {
  const { data, error } = useSWR(
    `group-find-${JSON.stringify(request)}`,
    () => GroupApi.getGroupWithImage(request),
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};
