"use client";

import React, { useEffect, useState, useRef } from "react";
import Image from "next/image";
import { useSession } from "next-auth/react";
import ScoreModalButton from "@/components/ScoreModalButton";
import { useAppContext } from "@/context/AppContextProvider";
import { bizUDPGothicBold } from "@/lib/utils/fonts";
import { ImageResponseData } from "@/restapi/image";
import { ScoreData } from "@/restapi/score";
import ImageIconComponent from "@/components/icons/ImageIconComponent";
import { useGroupGetWithImage } from "@/hooks/group/useGroupGetWithImage";
import { GroupImageDeleteButton } from "@/components/group/buttons/GroupImageDeleteButton";
import { usePathname } from "next/navigation";
import { URLConstants } from "@/constants/URLConstants";
import { useImageScoreExists } from "@/hooks/image/useImageScoreExists";
import LocateIcon from "@/components/icons/LocateIcon";
import "./style.css";
import { useTranslation } from "@/lib/react/i18n";

const ImageScoreCard: React.FC<{ image: ImageResponseData }> = ({ image }) => {
  const { data: session } = useSession();
  const user = session?.user;
  const [scoreExists, setScoreExists] = useState(image.scoreExists);
  const pathname = usePathname();
  const isGroupMaypage = /^\/group\/mypage\/[^/]+$/.test(pathname);
  const imageRef = useRef<HTMLDivElement>(null);

  const { visableMode, setVisableMode, settingMenuProps, setSettingMenuProps } = useAppContext();
  const [isScorolled, setIsScrolled] = useState(false);
  const [scoreData, setScoreData] = useState<ScoreData>({
    posts: image.posts ?? 0,
    averageScore: image.averageScore,
    totalScore: image.totalScore ?? 0,
  });

  const [isAdmin, setIsAdmin] = useState(false);
  const { data: group, isLoading } = useGroupGetWithImage({
    imageId: image.id,
    userId: user?.id ?? '',
  });

  const { data: scoreExistsData, isLoading: scoreExistsLoading } = useImageScoreExists({
    userUuid: user?.id ?? '',
    imageUuid: image.uuid,
  });

  useEffect(() => {
    if (group && group.adminIds) {
      setIsAdmin(group.adminIds.includes(user?.id ?? ''));
    }
  }, [group, isLoading]);

  useEffect(() => {
    if (!scoreExistsLoading && scoreExistsData) {
      setScoreExists(scoreExistsData.scoreExists);
    }
  }, [scoreExistsLoading, scoreExistsData]);



  // 平均点リアルタイム反映
  // const echo = useEcho();
  // useEffect(() => {
  //   if (echo) {
  //     echo
  //       .channel(`channel-score-post-${image.uuid}`)
  //       .listen("ScorePostEvent", (response: ScorePostEventResponse) => {
  //         const {averageScore, totalScore, posts, userId: _userId } = response;
  //         setScoreExists(true);
  //         setScoreData({
  //           averageScore,
  //           totalScore,
  //           posts,
  //         });
  //       });
  //   }
  // }, [echo]);


  // const handleClickAverage = (
  //   e: React.MouseEvent<HTMLDivElement, MouseEvent>
  // ) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   setIsModalOpen(true);
  // };

  const handleToggleVisibility = () => {
    if (settingMenuProps.isMeneuOpen) {
      setSettingMenuProps({ isMeneuOpen: false });
      return;
    }
    setVisableMode({ isShow: !visableMode.isShow });
  };

  const handleClickGroupMypageLink = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    if (group && typeof window !== "undefined") {
      window.location.href = `${URLConstants.group.mypage.pathname}/${group.id}`;
    }
  }

  const handleClickUserMypageLink = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (typeof window !== "undefined") {
      window.location.href = `${URLConstants.user.mypage.pathname}/${image.userProfile.uuid}`;
    }
  }

  useEffect(() => {
    if (imageRef.current) {
      const rect = imageRef.current.getBoundingClientRect();
      imageRef.current.style.transform = `translateY(${window.scrollY}px)`;
    }
    setIsScrolled(true);
  }, []);

  if (isLoading || scoreExistsLoading) return null;

  return (
    <div
      ref={imageRef}
      className={`${isScorolled ? "opacity-100" : "opacity-0"} relative w-100 h-screen`} onClick={handleToggleVisibility}>
      <div className="text-with-outline-shadow average-score">
        {typeof scoreData.averageScore === "number" &&
          scoreData.posts !== null && (
            <div
              // onClick={handleClickAverage}
              className={`mt-24 cursor-pointer avgScoreBox drop-shadow-[1px_1px_2px_rgba(0,0,0,0.8)] mix-blend-multiply ${visableMode.isShow ? "fade-in" : "fade-out"
                }`}
            >
              <span className="avgScore">{scoreData.averageScore}</span>
              <span className="avgTxt">avg.</span>
            </div>
          )}
      </div>
      <Image
        className={`object-cover ${visableMode.isShow ? "fade-in" : "fade-out"}`}
        alt={image.imageFile.fileName}
        src={image.imageFile.fileUrl}
        fill
        priority
      />
      <Image
        className={`object-contain ${visableMode.isShow ? "fade-out" : "fade-in"}`}
        alt={image.imageFile.fileName}
        src={image.imageFile.fileUrl}
        fill
        priority
      />
      <div className="mb-16">
        {user && (
          (group && (isAdmin || image.userProfile.uuid === user.id) && isGroupMaypage
            || image.userProfile.uuid === user.id
          ) &&
          <div className={`absolute top-32 right-4 ${visableMode.isShow ? "fade-in" : "fade-out"}`}>
            <GroupImageDeleteButton image={image} group={group} />
          </div>
        )}
      </div>
      <div>

        {!scoreExists && (

          <ScoreModalButton
            setScoreExists={setScoreExists}
            imageData={image}
            setScoreData={setScoreData} />
        )}
      </div>
      <div className="absolute w-full bottom-0 sm:bottom-[5%] left-0 px-2 pb-28 md:pb-20 grid grid-cols-2 grid-rows-1 gap-4">
        <div className={`flex flex-row items-end`}>
          <div className={`opacity-65 score-number ${bizUDPGothicBold.className} ${visableMode.isShow ? "fade-in" : "fade-out"}`}>
            <span className="opacity-65">{image.myScore}</span>
          </div>
          <div className="flex flex-col pb-2">
            <div className="mapMark">
              <LocateIcon className={`text-with-outline-shadow locate-icon ${visableMode.isShow ? "fade-in" : "fade-out"}`} />
            </div>
            <div className="ml-2">
              <div className="cityBtn">
                <span className={`text-white text-with-outline-shadow city-name ${visableMode.isShow ? "fade-in" : "fade-out"}`}>
                  {`${image.province} ${image.city}`}
                </span>
              </div>
              <div className="accountBtn">
                <span className={`text-white text-with-outline-shadow account-name ${visableMode.isShow ? "fade-in" : "fade-out"}`}>
                  @{image.userProfile.userId}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-end justify-end">
          {group && group.id !== undefined && (
            <div className={`cursor-pointer ${visableMode.isShow ? "fade-in" : "fade-out"}`} onClick={handleClickGroupMypageLink}>
              <ImageIconComponent fileURL={group?.iconFileUrl ?? ''} plusDisabledIcon={true} iconType={'Group'} />
            </div>
          )}
          <div className={`cursor-pointer ml-4  ${visableMode.isShow ? "fade-in" : "fade-out"}`} onClick={handleClickUserMypageLink}>
            <ImageIconComponent fileURL={image.userProfile?.userIconFile?.fileUrl ?? ''} plusDisabledIcon={true} />
          </div>
        </div>
      </div>
    </div >);
};

export default ImageScoreCard;
