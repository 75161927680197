/**
 * v0 by Vercel.
 * ロケーションアイコン
 */
export default function LocateIcon (props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="white"
      filter="url(#shadow)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
        <feDropShadow dx="2" dy="2" stdDeviation="2" floodColor="#565656" />
      </filter>
      <path d="M12 2C8.13401 2 5 5.13401 5 9C5 13.25 9 19 11.2929 21.2929C11.6834 21.6834 12.3166 21.6834 12.7071 21.2929C15 19 19 13.25 19 9C19 5.13401 15.866 2 12 2Z" />
      <circle cx="12" cy="9" r="2" />
    </svg>
  );
}
