"use client";

import { useTranslation } from "@/lib/react/i18n";
import React from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>
  alertMessages: string[];
}

const GroupImageDeleteModal: React.FC<ModalProps> = ({ isOpen, onClose, onConfirm, alertMessages }) => {
  const { t } = useTranslation();
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="bg-blue-100 p-6 rounded-md shadow-lg">
        <div className="text-center mb-4">
          <div className="flex  justify-center flex-col items-center max-h-48">
            {
              alertMessages.map((msg, index) => (
                <span key={index} className="block p-2 text-gray-800 bg-blue-100  border-blue-300">
                  {msg}
                </span>
              ))
            }
          </div>
        </div>
        <div className="flex justify-between mt-4">
          <button
            onClick={(e) => { e.stopPropagation(); onClose(); }}
            className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition"
          >
            {t('No')}
          </button>
          <button
            onClick={(e) => { e.stopPropagation(); onConfirm(); }}
            className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition"
          >
            {t('Yes')}
          </button>
        </div>
      </div>
    </div >
  );
};

export default GroupImageDeleteModal;