"use client";

import { ImageAPI, ImageSearchRequest, ImageResponse, ImagesResponseData } from "@/restapi/image";
import APP_CONSTANTS from "@/constants/AppConstants";
import useSWRInfinite from "swr/infinite";

/**
 * 画像データを取得するための非同期フェッチ関数
 * @param key - SWR のキャッシュキー
 * @param page - 現在のページ番号
 * @param request - API に送信するリクエストデータ
 * @returns 画像データのレスポンス
 */
const fetcher = async (key: string, page: number, request: ImageSearchRequest): Promise<ImageResponse> => {
  // console.log("🟠 API Request Payload:", request);
  return ImageAPI.search({ ...request, page });
};

/**
 * 画像一覧を検索するカスタムフック
 * @param request - 画像取得に必要なフィルタ情報
 * @param initialSize - 初回に取得するページ数
 * @returns 画像一覧データと、ロード状態・エラー状態・ページネーション制御関数
 */
export const useImageSearch = (request: ImageSearchRequest, initialSize: number): ImagesResponseData => {

  const { refreshInterval } = request;

  const getKey = (index: number, previousPageData: ImageResponse | null): string | null => {
    if (previousPageData && !previousPageData.data.length) return null;
    return `${JSON.stringify(request)}-images?page=${index + 1}`;
  };

  const fetcherWrapper = (key: string): Promise<ImageResponse> => {
    const match = key.match(/page=(\d+)/);
    const page = match ? parseInt(match[1], 10) : 1;
    return fetcher(key, page, request);
  };

  const { data, error, size, setSize, isValidating } = useSWRInfinite<ImageResponse>(getKey, fetcherWrapper, {
    initialSize,
    refreshInterval: refreshInterval ? refreshInterval : APP_CONSTANTS.REFETCH_INTERVAL_TIME,
    revalidateOnFocus: false, // フォーカス時のリフェッチを無効化
    revalidateIfStale: false, // キャッシュがあっても再フェッチしない
    revalidateOnReconnect: false, // ネットワーク復帰時の再フェッチを無効化
    dedupingInterval: 0, // 同じリクエストを毎回実行する
    provider: () => new Map(), // 全てのキャッシュを保持しない
  });

  const isLoading = !data && !error;
  const lastPage = data ? data[data.length - 1]?.lastPage ?? 1 : 1;
  const hasMore = data ? data[data.length - 1]?.currentPage < lastPage : false;
  const items = data ? data.flatMap((page) => page.data) : [];

  const loadMore = () => {
    if (!isValidating && hasMore) {
      setSize(size + 1);
    }
  };

  return {
    items,
    isLoading,
    isValidating,
    isError: !!error,
    loadMore,
    hasMore,
  };
};