"use client";

import { useAppContext } from "@/context/AppContextProvider";
import Link from "next/link";
import Image from "next/image";
import ImageIconComponent from "@/components/icons/ImageIconComponent";
import { User } from "next-auth";
import { useUserGetProfile } from "@/hooks/user/useUserGetProfile";
import { useTranslation } from "@/lib/react/i18n";
import { FaChevronLeft } from "react-icons/fa6";
import { FC, MouseEvent } from "react";
import { URLConstants } from "@/constants/URLConstants";
import { FilterImageCategory, TabItem } from "@/components/features/gallery/hooks/useGalleryCategory";
import { usePathname, useRouter } from "next/navigation";
import { useSession } from "next-auth/react";
import SignInButton from "@/components/SignInButton";

type Props = {
  tabs: TabItem[];
  user?: User;
  gallaryCategory: FilterImageCategory;
  handleClickLeftIcon: () => void;
}

const FullScreenGalleryHeader: FC<Props> = ({ user, gallaryCategory, handleClickLeftIcon, tabs }) => {
  const { t, loading: tLoading } = useTranslation();
  const pathname = usePathname();
  const { visableMode, setScreenType } = useAppContext();
  const router = useRouter();
  const { data: session } = useSession();
  const sessionUser = session?.user;
  const { data: userProfileData, isLoading } = useUserGetProfile({
    userUuid: sessionUser?.id ?? '',
  })



  const handleTabClick = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>, tabItem: TabItem) => {
    e.stopPropagation();
    if (tabItem.clickTab) {
      tabItem.clickTab()
    };
  }

  if (isLoading || tLoading) {
    return <></>;
  }

  return (
    <div
      className={`fixed top-0 w-full min-w-screen-sm flex flex-row-reverse justify-between bg-black py-2 settingArea ${visableMode.isShow ? "fade-in" : "fade-out"}`}
      style={{ zIndex: 500 }}
    >
      <div className="w-full m-0 p-0">
        <div>
          <div className="flex flex-col">
            <div className="flex justify-between bg-black">
              <div
                className="mx-2 max-w-28"
              >
                <Link className="text-white max-w-28" href="/">
                  <h1>
                    <Image
                      alt="picsle logo"
                      src="/picsle_logo.svg"
                      width={300}
                      height={300}
                      fill={false}
                      priority={false}
                    />
                  </h1>
                </Link>
              </div>
              {userProfileData ? (
                <div
                  onClick={(e) => { e.stopPropagation(); setScreenType('grid'); router.push(URLConstants.user.mypage.pathname) }}
                  className={`mx-2 cursor-pointer ${visableMode.isShow ? "fade-in" : "fade-out"}`}>
                  <ImageIconComponent
                    iconType={'User'}
                    borderEnable={false}
                    plusDisabledIcon={true}
                    fileURL={userProfileData?.userIconFile?.fileUrl ?? ''}
                  />
                </div>
              )
                : <SignInButton />
              }
            </div>
          </div>
          <div
            className="flex justify-between bg-black"
          >
            <div className="text-white" style={{ zIndex: 600 }}>
              <button
                onClick={(e) => { e.stopPropagation(); handleClickLeftIcon(); }}
                className="cursor-pointer">
                <FaChevronLeft className="h-6 w-6" />
              </button>
            </div>
            <div className=" w-full">
              <div className={`text-white flex max-w-[600px] mx-auto justify-around my-2`}>
                {
                  tabs.map((tabItem) => (
                    <div
                      key={`${pathname}-${tabItem.value}`}
                      className={`text-[min(3vw,14px)] mx-2 ${gallaryCategory === tabItem.value ? 'border-b-2 border-gray-100 rounded-none' : 'cursor-pointer'}`}
                      onClick={(e) => { handleTabClick(e, tabItem) }}
                    >
                      {tabItem.label}
                    </div>
                  ))
                }
              </div>
            </div>

          </div>
        </div>
      </div>

    </div >
  );
};

export default FullScreenGalleryHeader;
