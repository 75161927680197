'use client';

import { useTranslation } from '@/lib/react/i18n';
import { isHttps } from '@/lib/utils';
import Image from 'next/image';
import React from 'react';

type IconType = 'User' | 'Group';

export type IconSizeProps = {
	width: number;
	height: number;
}

interface UserIconProps {
	fileURL?: string;
	plusDisabledIcon?: boolean;
	size?: IconSizeProps;
	className?: string;
	iconType?: IconType;
	count?: number;
	borderEnable?: boolean;
	borderColor?: string;
}


const ImageIconComponent: React.FC<UserIconProps> = ({ fileURL, plusDisabledIcon = true, className, size = {
	width: 50,
	height: 50,
}, iconType = 'User', count = 0, borderEnable = false, borderColor = '' }) => {
	const styles: React.CSSProperties = {
		position: 'absolute',
		bottom: '2px',
		right: '2px',
		width: size.width / 4,
		height: size.height / 4,
		border: '2px solid red',
		borderColor: 'red',
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: size.width / 4,
		color: 'red',
	};


	const countStyles: React.CSSProperties = {
		position: 'absolute',
		bottom: '2px',
		right: '2px',
		width: size.width / 3,
		height: size.height / 3,
		borderRadius: '50%',
		borderColor: 'red',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: size.width / 5,
		color: 'red',
	};

	const { t } = useTranslation();

	return (borderEnable ?
		<div className={`${borderColor} flex items-center justify-center`} style={{
			borderRadius: '50%',
			padding: '2px',
		}} >
			<div
				className={className}
				style={{
					position: 'relative',
					width: size.width,
					height: size.height,
					backgroundColor: '#f3f4f6',
					borderRadius: '50%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					fontSize: (size.width / 6),
					color: '#000'
				}}>
				{fileURL ? (
					<Image
						className='object-cover'
						src={fileURL}
						alt=""
						width={size.width}
						height={size.height}
						style={{
							borderRadius: '50%',
							width: '100%',
							height: '100%',
						}}
						priority={true}
					/>
				) : (
					iconType === 'User'
						? <span>{t('User')}<br />{t('Icon')}</span>
						: <span>{t('Group')}<br />{t('Icon')}</span>
				)
				}
				{!plusDisabledIcon &&
					<div style={styles}>+</div>
				}
				{count > 0 &&
					<div
						className='bg-red-600 border-red-600 p-1'
						style={countStyles}>{count > 99 ? 99 : count}</div>
				}
			</div >
		</div>
		: <div
			className={className}
			style={{
				position: 'relative',
				width: size.width,
				height: size.height,
				backgroundColor: '#f3f4f6',
				borderRadius: '50%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				fontSize: (size.width / 6),
				color: '#000'
			}}>
			{fileURL ? (
				isHttps(fileURL)
					?
					<Image
						className='object-cover'
						src={fileURL}
						alt=""
						width={size.width}
						height={size.height}
						style={{
							borderRadius: '50%',
							width: '100%',
							height: '100%',
						}}
						priority={true}
					/>
					// eslint-disable-next-line @next/next/no-img-element
					: <img
						className='object-cover'
						src={fileURL}
						alt=""
						width={size.width}
						height={size.height}
						style={{
							borderRadius: '50%',
							width: '100%',
							height: '100%',
						}}
					/>
			) : (
				iconType === 'User'
					? <span>{t('User')}<br />{t('Icon')}</span>
					: <span>{t('Group')}<br />{t('Icon')}</span>
			)
			}
			{!plusDisabledIcon &&

				<div style={styles}>+</div>
			}
			{count > 0 &&
				<div
					className='bg-red-600 border-red-600 p-1'
					style={countStyles}>{count > 99 ? 99 : count}</div>
			}
		</div>
	);
};

export default ImageIconComponent;
