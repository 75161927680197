"use client";

import React, { useState, MouseEvent, Dispatch, SetStateAction, FC } from "react";
import { Button } from "@/components/ui/button";
import ScoreInputModal from "@/components/score/InputModal";
import { useAppContext } from "@/context/AppContextProvider";
import { useTranslation } from "@/lib/react/i18n";
import { ImageResponseData } from "@/restapi/image";
import { ScoreData } from "@/restapi/score";
import "./scoremodalButton.css";


type Props = {
  setScoreData: Dispatch<SetStateAction<ScoreData>>;
  imageData: ImageResponseData;
  setScoreExists: (value: boolean) => void;
}

const ScoreModalButton: FC<Props> = ({ setScoreData, setScoreExists, imageData }) => {
  const [showModal, setShowModal] = useState(false);
  const { visableMode } = useAppContext();
  const { t } = useTranslation();

  const handleCommentClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShowModal(true);
  };

  return (
    <>
      <Button
        className={`absolute bottom-48 left-1/2 transform -translate-x-1/2 w-20 h-20 rounded-full text-white text-[min(2.8vw,12px)] tracking-wider flex justify-center items-center bg-gradient-to-t from-white via-[rgba(215,0,255,1)] via-30% to-[rgba(50,0,255,1)] to-70%
        ${visableMode.isShow ? "fade-in" : "fade-out"}`}
        style={{ zIndex: 30 }}
        variant="link"
        onClick={handleCommentClick}
      >
        {t("SCORING")}
      </Button>
      {showModal && (
        <ScoreInputModal
          imageResponseData={imageData}
          setScoreData={setScoreData}
          setScoreExists={setScoreExists}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default ScoreModalButton;
