"use client";

import useSWR from "swr";
import { ImageAPI, ImageScoreExistsRequest } from "@/restapi/image";


const useImageScoreExists = (request: ImageScoreExistsRequest) => {
  const { data, error } = useSWR(
    `image-score-exists-${JSON.stringify(request)}`,
    () => ImageAPI.scoreExists(request),
    { refreshInterval: 2000 }
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export { useImageScoreExists };
